import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import ContactUsFormContainer from 'components/ContactUsFormContainer/ContactUsFormContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const ContactUsFormPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.contactUsFormYaml.title} />
      <ContactUsFormContainer
        data={data.contactUsFormYaml.contactUsFormContainer}
      />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default ContactUsFormPage;

export const pageQuery = graphql`
  query ContactUsFormPageQuery {
    contactUsFormYaml {
      id
      title
      contactUsFormContainer {
        form {
          name
          submitTitle
          formFields {
            name
            label
            type
            required
            validators
            fieldErrors {
              key
              value
            }
            options {
              label
              value
            }
          }
          formErrors {
            key
            value
          }
        }
      }
    }
  }
`;
