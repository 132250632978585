import React, { Component, Fragment } from 'react';
import { navigate } from 'gatsby-link';
import { Form as FinalForm } from 'react-final-form';
import { PrimaryButton } from 'components/Shared/Button/Button';
import FormField from 'components/Shared/Form/FormField/FormField';
import { sendContactUsRequest } from 'components/Shared/FormHandlers';
import Validators, {
  composeValidators,
  prepareErrors,
} from 'components/Shared/Form/validators';
import './Form.scss';

class Form extends Component {
  constructor(props) {
    super(props);
    this.decorators = [];
    this.validators = {};
    this.state = {
      submitError: null,
    };
    var self = this;
    this.props.data.form.formFields.forEach(field => {
      var validators = [];
      const messages = field.fieldErrors
        ? prepareErrors(field.fieldErrors)
        : {};
      self.validators[field.name] = [];
      if (field.validators) {
        field.validators.forEach(validator => {
          if (validator in Validators) {
            validators.push(Validators[validator](messages[validator]));
          }
        });
      }
      if (field.required) {
        validators.push(Validators.required(messages['required']));
      }
      self.validators[field.name] = composeValidators(...validators);
    });
  }

  submit = async values => {
    const errorMessages = {
      ...this.props.data.form.formErrors.reduce((obj, err) => {
        obj[err.key] = err.value;
        return obj;
      }, {}),
    };
    try {
      await sendContactUsRequest(values);
      this.setState({
        submitError: null,
      });
      navigate('/thank-you');
    } catch (e) {
      this.setState({
        submitError: errorMessages.genericError,
      });
      return e;
    }
  };

  render() {
    const { data } = this.props;
    return (
      <Fragment>
        <h2 className="form-title">{data.form.name}</h2>
        <FinalForm
          decorators={this.decorators}
          onSubmit={this.submit}
          render={({ handleSubmit, submitting, submitSucceeded }) => (
            <form className="form-container row" onSubmit={handleSubmit}>
              {data.form.formFields.map(field => (
                <div className="col-12 form-container__row" key={field.name}>
                  <FormField
                    data={field}
                    validate={this.validators[field.name]}
                  />
                </div>
              ))}
              <div className="col-12 form-container__button-row">
                <PrimaryButton
                  disabled={
                    submitting || (submitSucceeded && !this.state.submitError)
                      ? 'disabled'
                      : ''
                  }
                  type="submit"
                >
                  {data.form.submitTitle}
                </PrimaryButton>
                <div className="form-container__error">
                  {this.state.submitError}
                </div>
              </div>
            </form>
          )}
        />
      </Fragment>
    );
  }
}

export default Form;
