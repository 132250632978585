import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import Form from './Form/Form';
import './ContactUsFormContainer.scss';

const ContactUsFormContainer = ({ data }) => (
  <div className="contact-us-container">
    <div className="contact-us-container__padding-box">
      <ContentBox>
        <Form data={data} />
      </ContentBox>
    </div>
  </div>
);

export default ContactUsFormContainer;
