import axios from 'axios';

import { updateAccount } from './CCPA';
import { API_BASE_URL, API_CONTACTUS_URL } from './Constants';

const contactUsURL = `${API_BASE_URL}${API_CONTACTUS_URL}`;

export const sendContactUsRequest = async data => {
  updateAccount(data.email);
  const response = await axios.post(contactUsURL, data, {
    headers: {
      'Content-type': 'application/json',
    },
  });
  return response;
};
